<style scoped lang="scss">
@import "@/assets/scss/redeem.scss";
</style>

<template>
  <div class="min-h-[500px] pb-[200px]">

    <div class="wrap flex flex-col gap-[40px]">
      <div class="top-area">
        <div class="title">Redeem</div>
      </div>

      <div class="flex gap-[40px] w-[1200px] m-auto mobile:flex-col mobile:w-full mobile:px-[16px]">
        <div class="flex flex-col gap-[20px] w-full items-center">
          <div class="w-[400px] h-[400px] mobile:w-[260px] mobile:h-[260px] bg-cover bg-center "
               :style="{backgroundImage:`url(${wineInfo ? wineInfo.image : ''})`}"/>
          <div class="w-full h-[1px] bg-lightLightDark"/>
          <div class="flex flex-col gap-[8px] w-full">
            <p class="text-[24px] font-[Suit-Medium] w-full text-left m-0">{{ wineInfo ? wineInfo.name : '' }}</p>
          </div>
        </div>

        <div class="w-full flex flex-col gap-[40px]">
          <div class="flex flex-col gap-[20px]">

            <div class="flex flex-col gap-[4px]">
              <p class="text-[16px] font-[Suit-Medium] text-darkDark leading-[28px] flex gap-[2px] m-0">Last Name<span
                  class="text-[#D1504B] text-[16px] font-[Suit-Medium]  leading-[28px]">*</span></p>
              <input type="text"
                     class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                     placeholder="성을 입력해 주세요." v-model="lastName"
                     :style="{borderColor: lastNameValidation ? '#CBCBCB' : '#D1504B'}"
              />
            </div>

            <div class="flex flex-col gap-[4px]">
              <p class="text-[16px] font-[Suit-Medium] text-darkDark leading-[28px] flex gap-[2px] m-0">First Name<span
                  class="text-[#D1504B] text-[16px] font-[Suit-Medium]  leading-[28px]">*</span></p>
              <input type="text"
                     class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                     placeholder="이름을 입력해 주세요." v-model="firstName"
                     :style="{borderColor: firstNameValidation ? '#CBCBCB' : '#D1504B'}"
              />
            </div>
            <div class="flex flex-col gap-[4px]">
              <p class="text-[16px] font-[Suit-Medium] text-darkDark leading-[28px] flex gap-[2px] m-0">연락처<span
                  class="text-[#D1504B] text-[16px] font-[Suit-Medium]  leading-[28px]">*</span></p>
              <input type="text"
                     class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                     placeholder="연락처를 입력해 주세요." v-model="phoneNumber" @input="phoneHandle"
                     :style="{borderColor: phoneValidation ? '#CBCBCB' : '#D1504B'}"
              />
            </div>
            <div class="flex flex-col gap-[4px]">
              <p class="text-[16px] font-[Suit-Medium] text-darkDark leading-[28px] flex gap-[2px] m-0">배송지<span
                  class="text-[#D1504B] text-[16px] font-[Suit-Medium]  leading-[28px]">*</span></p>
              <div class="flex flex-col gap-[10px]">
                <div class="flex gap-[4px]">
                  <!--
                  <input type="text"
                         class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                         placeholder="우편번호를 검색해주세요" v-model="postcode" readonly @click="searchZipCode">
                  <div
                      class="w-[80px] h-[52px] bg-[#A8221A] rounded-[5px] leading-[52px] text-center cursor-pointer text-[16px] text-white font-[Suit-Bold]"
                      @click="searchZipCode">
                    검색
                  </div>
                  -->
                  <input class="text-[16px] border border-[1px] border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                          placeholder="우편 번호를 입력해주세요" v-model="postcode" :validation="postcodeValidation" :data="postcode" :style="{borderColor: postcodeValidation ? '#CBCBCB' : '#D1504B'}"
                  />
                </div>

                <!--
                <input type="text"
                       class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                       placeholder="주소를 검색해 주세요." v-model="defaultAddress" readonly @click="searchZipCode">
                -->
                <input type="text"
                       class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                       placeholder="상세 주소를 입력해 주세요." v-model="defaultAddress" :validation="addressValidation" :data="defaultAddress" :style="{borderColor: addressValidation ? '#CBCBCB' : '#D1504B'}"
                />
              </div>
            </div>
<!--
            <div class="flex flex-col gap-[4px]">
              <p class="text-[16px] font-[Suit-Medium] text-darkDark leading-[28px] flex gap-[2px] m-0">개인통관번호<span
                  class="text-[#D1504B] text-[16px] font-[Suit-Medium]  leading-[28px]">*</span></p>
              <input type="text"
                     class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                     placeholder="개인통관번호를 입력해 주세요." v-model="customerNumber">
            </div>-->

            <div class="gap-[4px] flex flex-col ">
              <div class="text-[16px] font-[Suit-Medium] text-darkDark leading-[28px]">
                배송방법
              </div>
              <div class="input-delivery-type-radio-wrap flex gap-[40px] mobile:flex-wrap mobile:gap-y-[4px]">
                <label class="winex-radio" @click="openAlertModal('알림','리퍼 컨테이너 이외의 다른 배송방법은 현재 준비중 입니다.')"><input
                    type="radio" name="radio-test" value="1" v-model="shippingMethod"
                    disabled><span>익스 프레스</span></label>
                <label class="winex-radio" @click="openAlertModal('알림','리퍼 컨테이너 이외의 다른 배송방법은 현재 준비중 입니다.')"><input
                    type="radio" name="radio-test" value="2" v-model="shippingMethod"
                    disabled><span>스탠다드 에어</span></label>
                <label class="winex-radio"><input type="radio" name="radio-test" value="3"
                                                  v-model="shippingMethod"><span>리퍼 컨테이너</span></label>
              </div>
              <h2 class="text-[14px] text-lightDark bg-no-repeat bg-[length:16px] pl-[24px] leading-[24px] bg-[center_left]"
                  :style="{backgroundImage: `url('${require('@/assets/images/icon/info-icon.svg')}')`}">병수와 상관 없이 바로 배송을
                시작합니다.</h2>
            </div>

            <div class="px-[20px] py-[20px] bg-lightLight rounded-[10px] flex items-center justify-between">
              <p class="text-[16px] text-darkDark m-0 leading-[28px]">결제 배송비</p>
              <p class=" m-0 text-[20px] font-[Suit-Bold]">{{ $numberFormat(shippingPrice).toString().split('.')[0] }}원</p>
            </div>


          </div>

          <div class="w-full flex gap-[10px]">
            <div
                class="w-full h-[52px] border border-lightLightDark rounded-[5px] text-[16px] text-darkDark text-center leading-[52px] cursor-pointer "
                @click="$router.go(-1)"
            >
              Previous
            </div>
            <div
                class="w-full h-[52px] bg-[#A8221A] rounded-[5px] text-[16px] text-white text-center leading-[52px] cursor-pointer"
                @click="isOpenEditRedeemConfirm=true"
            >
              수정하기
            </div>
          </div>
        </div>

      </div>
    </div>


    <AlertModal
        :title="alertTitle"
        :content="alertContent"
        :pc-width="400"
        :button-class="'primary-btn'"
        :is-open="defaultAlertModalOpen"
        :confirm-click-btn="closeAlertModal"/>

    <AlertModal v-if="isOpenRedeemEditAlert"
        title="수정완료"
        content="리딤 수정이 완료되었습니다"
        :pc-width="400"
        :button-class="'primary-btn'"
        :is-open="isOpenRedeemEditAlert"
        :confirm-click-btn="closeCompleteAlert"/>

    <ConfirmModal
        :title="'Redeem을 수정하시겠습니까?'"
        :content="'리딤내용이 변경되며, 배송이 시작되면 변경이 불가능합니다.'"
        :button-content="'Redeem'"
        :button-class="'primary-btn'"
        :confirm-click-btn="edit"
        :cancel-button-content="'Cancel'"
        :cancel-click-btn="() => {isOpenEditRedeemConfirm=false}"
        :cancel-button-class="'outline-white-lld-btn'"
        :is-open="isOpenEditRedeemConfirm"
        :is-icon="true"
        :custom-icon="require('@/assets/images/icon/redeem-icon.png')">

    </ConfirmModal>

  </div>
</template>

<script>
import getContractApi from "@/api/contract";
import AlertModal from "@/components/modals/AlertModal";
import {mapState} from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal";

export default {
  components: {ConfirmModal, AlertModal},
  data() {
    return {
      redeemInfo: null,
      wineInfo: null,

      defaultAlertModalOpen: false,
      alertTitle: '알림',
      alertContent: '준비중 입니다.',

      market: null,
      contract: null,

      shippingPrice: 0,

      firstName: '',
      lastName: '',
      phoneNumber: '',
      postcode: '',
      defaultAddress: '',
      detailAddress: '',
      customerNumber:'',
      shippingMethod: 3,

      isOpenEditRedeemConfirm: false,
      isOpenRedeemEditAlert: false,

      firstNameValidation : true,
      lastNameValidation : true,
      phoneValidation : true,
      postcodeValidation : true,
      addressValidation : true,

    }
  },
  created() {
    let _this = this

    this.contract = getContractApi();
    this.market = this.contract.getWineMarket()
    this.$store.dispatch('redeem/getReedem', {redeem_no: this.$route.params.redeem_no}).then((data) => {

      _this.redeemInfo = data;
      _this.firstName = data.first_name;
      _this.lastName = data.last_name;
      _this.phoneNumber = data.phone_number;
      _this.postcode = data.postcode;
      _this.defaultAddress = data.default_address;
      _this.detailAddress = data.detail_address;
      _this.customerNumber = data.personal_customs_number;
      _this.shippingMethod = data.shipping_method

      _this.phoneHandle();

      // 위 data의 NFT_KEY + user_key값 으로 본인 소유의 와인인지 확인. 본인 소유의 와인이 아니라면 오류 반환
      // 본인 소유의 와인이라면 해당 NFT 정보 가져와서 위 상세 정보 반환하여 화면에 노출

      _this.market.fetchItemInfoByTokenId(data.nft_key).then((data) => {
        _this.wineInfo = data;
        /*if (_this.wineInfo.minter) {
          _this.$store.dispatch('creator/getCreator', {wallet_address: self.wineInfo.wine_nft.minter}).then((data) => {
            _this.wineInfo.propertyList.minter = data.nickname
          })
        }*/
      })
    }).catch((err) => {
      console.dir(err)
      _this.openAlertModal('오류', '리딤 정보를 가져오는 중 오류가 발생하였습니다.')
    })
  },
  computed:{
    ...mapState('user', ['walletAddress'])
  },
  methods: {
    openAlertModal(title, content) {
      this.alertTitle = title
      this.alertContent = content
      this.defaultAlertModalOpen = true
    },
    closeAlertModal() {
      this.defaultAlertModalOpen = false
    },
    searchZipCode: function () {
      let _this = this
      new window.daum.Postcode({
        oncomplete: function (data) {
          _this.postcode = data.zonecode
          _this.defaultAddress = data.roadAddress
        }
      }).open();
    },
    phoneHandle() {
      this.phoneNumber = this.phoneNumber.replaceAll(/[^0-9]/g, '');
    },
    validation() {
      let validator = true;
      let regExpValidation = '';
      const firstNameRegExp = /^[가-힣a-zA-Z]{1,}$/g;

      if(this.firstName) {
        if(!firstNameRegExp.test(this.firstName)){
          this.firstNameValidation = false;
          validator = false;
          regExpValidation = '이름 입력 내용을 확인해주시기 바랍니다.';
        }else{
          this.firstNameValidation = true;
        }
      }
      else {
        this.firstNameValidation = false;
        validator = false;
        regExpValidation = '이름을 입력해주시기 바랍니다.';
      }


      const lastNameRegExp = /^[가-힣a-zA-Z]{1,}$/g;

      if(this.lastName) {
        if(this.lastName && !lastNameRegExp.test(this.lastName)){
          this.lastNameValidation = false;
          validator = false;
          regExpValidation = '성 입력 내용을 확인해주시기 바랍니다.';
        }else{
          this.lastNameValidation = true;
        }
      }
      else {
        this.lastNameValidation = false;
        validator = false;
        regExpValidation = '성을 입력해주시기 바랍니다.';
      }



      if(this.phoneNumber) {
        if(this.phoneNumber && this.phoneNumber.replaceAll(/[^0-9]/g, '').length !== 11){
          this.phoneValidation = false;
          validator = false;
          // console.dir(3)
          regExpValidation = '전화번호 입력 내용을 확인해주시기 바랍니다.';
        }else{
          this.phoneValidation = true;
        }
      }
      else {
        this.phoneValidation = false;
        validator = false;
        regExpValidation = '전화번호를 입력해주시기 바랍니다.';
      }

      const postcodeRegExp = /^[0-9]{1,10}$/;
      if(this.postcode) {
        if(!postcodeRegExp.test(this.postcode)){
          this.postcodeValidation = false;
          validator=false;
          regExpValidation = '우편번호를 확인해주시기 바랍니다.';
        }
        else{
          this.postcodeValidation = true;
        }
      }
      else {
        this.postcodeValidation = false;
        validator=false;
        regExpValidation = '우편번호를 입력해주시기 바랍니다.';
      }

      if(this.defaultAddress) {
        this.addressValidation = true;
      }
      else {
        this.addressValidation = false;
        validator=false;
        regExpValidation = '상세주소를 입력해주시기 바랍니다.';
      }

      if(!validator){
        // this.openAlertModal('오류', '입력사항을 확인해주시기 바랍니다.');
        this.openAlertModal('오류', regExpValidation);
      }

      return validator;
    },
    edit(){

      this.isOpenEditRedeemConfirm = false;
      if(this.validation()){
        const data = {
          'wallet_address': this.walletAddress,
          'first_name': this.firstName,
          'last_name': this.lastName,
          'phone_number': this.phoneNumber,
          'postcode': this.postcode,
          'default_address': this.defaultAddress,
          'detail_address' : '',
          // 'detail_address': this.detailAddress,
          'personal_customs_number': this.customerNumber,
          'shipping_method': this.shippingMethod,
          'wine_nft_no': this.redeemInfo.wine_nft.wine_nft_no,
          'redeem_no': this.$route.params.redeem_no,
          '_method': "put"
        }

        const _this = this;
        _this.$store.dispatch('redeem/editRedeem', data).then(() => {
          _this.isOpenRedeemEditAlert = true;
        }).catch((err) => {
          console.dir(err)
          this.openAlertModal('오류', '리딤수정이 실패했습니다. 잠시 후 시도해주세요.')
        })
      }
    },
    closeCompleteAlert(){
      this.$router.go(-1)
    }
  }
}
</script>